<template>
    <div class="notice-container">
        <div class="notice-title-container">
            <span class="notice-title">
                {{notice.title}}
            </span>
        </div>
        <el-divider content-position="right">
            <el-space >
                <span>
                    {{notice.modifiedBy}}
                </span>
                <span>
                    {{notice.updateTime}}
                </span>
            </el-space>
        </el-divider>
        <div class="notice-body-container" v-html="notice.content">
        </div>
        <el-divider v-if="notice.attachments && notice.attachments.length>0" content-position="left"><i class="el-icon-document"></i> 附件</el-divider>
        <el-space direction="vertical" alignment="start">
            <el-link v-for="(attachment,index) in notice.attachments" :key="index" class="notice-attachment" type="primary" href="javascript:void(0);" @click="onAttachmentDownloadClick(attachment)">
                {{attachment.fileName}}
            </el-link>
        </el-space>
    </div>
</template>

<script>
import HomeApi from '@/apis/home/home'
export default {
    props:{
        data:{
            type: Object,
            default: null
        }
    },
    data(){
        return{
            notice: this.data
        }
    },
    methods:{
        onAttachmentDownloadClick(attachment){
            HomeApi.exportAsExcel(
                `notices/download/${attachment.url}`,
                null,
                attachment.fileName
            );
        }
    }
}
</script>
<style lang="scss" scoped>
.notice-container{
    .notice-title-container{
        text-align: center;
        .notice-title{
            font-size: 32px;
        }
    }
    .notice-body-container{

    }
}
</style>