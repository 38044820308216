import api from "../api";
/**
 * 管理员用户相关api
 */
export class HomeApi extends api {

  /**
   * 获取通知公告
   * @param {*} 请求参数
   * @param {*} 指定api
   */
  // getNotices() {
  //   return this.request({
  //     url: `${this.servicePath}/${this.modulePath}/notices`,
  //     method: 'post',
  //     pageInfo: {
  //       pageIndex: 1,
  //       pageSize: 5,
  //       orders: ['placement,id', 'DESC'],
  //     },
  //     data: {}
  //   })
  // }

  /**
   * 获取我的关注
   * @param {*} 请求参数
   * @param {*} 指定api
   */
  // getSubscriptions() {
  //   return this.request({
  //     url: `${this.servicePath}/${this.modulePath}/subscriptionProject/page`,
  //     method: 'post',
  //     pageInfo: {
  //       pageIndex: 1,
  //       pageSize: 5,
  //       orders: ['createTime', 'DESC']
  //     },
  //     data: {}
  //   })
  // }

}

export default new HomeApi("home");